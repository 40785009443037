document.addEventListener('DOMContentLoaded', event => {
  function resizeCards() {
    const allCards = document.querySelectorAll('.card')
    const cardGroupsHeights = {}
    allCards.forEach(item => {
      const { cardGroup } = item.dataset
      if (cardGroup) {
        item.querySelectorAll('.front').forEach(front => {
          front.style.height = 'auto'
          cardGroupsHeights[cardGroup] = Math.max(front.scrollHeight, cardGroupsHeights[cardGroup] || 0)
          front.style.height = '100%'
        })
        item.querySelectorAll('.back').forEach(back => {
          back.style.height = 'auto'
          cardGroupsHeights[cardGroup] = Math.max(back.scrollHeight, cardGroupsHeights[cardGroup] || 0)
          back.style.height = '100%'
        })
      }
    })
    allCards.forEach(item => {
      const { cardGroup, cardDiff } = item.dataset
      if (cardGroup) {
        item.style.height = +cardGroupsHeights[cardGroup] + (+cardDiff || 0)
      }
    })
  }

  window.onresize = resizeCards

  resizeCards()
})
