import './accordion'
import './dropdown'
import './flipping-cards'
import './resizing-cards'

const serialize = data => {
  const values = {}
  for (const [key, value] of data) {
    if (values[key] !== undefined) {
      if (!Array.isArray(values[key])) {
        values[key] = [values[key]]
      }
      values[key].push(value)
    } else {
      values[key] = value
    }
  }
  return values
}

window.onload = () => {
  // Smooth Scroll
  const smoothContactButton = document.querySelector('.smooth-contact-button')
  const smoothContentButton = document.querySelector('.smooth-content-button')
  const contactFormElement = document.querySelector('#contact-form')
  const contentElement = document.querySelector('#content')

  if (smoothContactButton && contactFormElement) {
    smoothContactButton.addEventListener('click', (evt) => {
      evt.preventDefault()
      evt.stopPropagation()
      if (contactFormElement) contactFormElement.scrollIntoView({ behavior: 'smooth' })
    })
  }
  if (smoothContentButton && contentElement) {
    smoothContentButton.addEventListener('click', (evt) => {
      evt.preventDefault()
      evt.stopPropagation()
      if (contentElement) contentElement.scrollIntoView({ behavior: 'smooth' })
    })
  }

  // Feedback forms
  document.querySelectorAll('.input-subject').forEach(item => {
    item.style.display = 'none'
  })

  document.querySelectorAll('.feedback-forms form').forEach(item => {
    item.addEventListener('submit', event => {
      event.preventDefault()
      const id = event.target.id
      const phpScript = id === 'feedback-form-join' ? 'join.php' : 'contact.php'
      event.target.querySelector('.success-message').style.display = 'none'
      event.target.querySelector('.error-message').style.display = 'none'
      event.target.querySelector('button').setAttribute('disabled', 'disabled')
      try {
        const values = serialize(new window.FormData(event.target))
        fetch(`https://service.bite.social/armstrong/${phpScript}`, {
          method: 'POST',
          body: JSON.stringify({
            user: {
              name: values.name,
              email: values.email,
              messageBody: values.messageBody,
              language: values.language,
              surname: values.surname,
              company: values.company,
              phone: values.phone,
              subject: values.subject
            }
          }),
          headers: {
            'Content-Type': 'application/json',
            token: 'aklsjdaskjho38woisdfhskdjfhoiadlks'
          }
        })
          .then(response => {
            if (response.status >= 400) {
              event.target.querySelector('.error-message').style.display = 'block'
            } else {
              // eslint-disable-next-line max-nested-callbacks
              event.target.querySelectorAll('input, textarea').forEach(input => {
                input.value = ''
              })

              event.target.querySelector('.success-message').style.display = 'block'
            }
            event.target.querySelector('button').removeAttribute('disabled')
            return response
          })
          .catch(error => {
            console.error(error)
            event.target.querySelector('.error-message').style.display = 'block'
            event.target.querySelector('button').removeAttribute('disabled')
          })
      } catch (e) {
        console.error(e)
        event.target.querySelector('.error-message').style.display = 'block'
        event.target.querySelector('button').removeAttribute('disabled')
      }
    })
  })
}
